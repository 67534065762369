import React, { useContext } from "react";
import styles from "./C01_SiteHeader.module.scss";
import HeaderSearch from "@components/C01.2_HeaderSearch/C01.2_HeaderSearch";
import SearchSvg from "../../public/image/svg/search.svg";
import ProfileSvg from "../../public/image/svg/profile.svg";
import Link from "@components/Link/Link";
import HamburgerSvg from "../../public/image/svg/menu.svg";
import { UserContext } from "../../context/user";
import { themr } from "@friendsofreactjs/react-css-themr";
import { GeneralLink } from "@customTypes/generalLink";
import { GeneralLinkNornalized } from "@customTypes/generalLink";
import { NavLinkList } from "@customTypes/megaNav";

import MegaNav from "./MegaNav";
import clsx from "clsx";
import MainNav from "./MainNav";
import { NavigationStateContext } from "../../context/navigationContext";
import Image, { NextImageProps } from "@components/Image/NextImage";
import { navigationGAClick } from "@utilities/navigationGAClick";
import { useDevice } from "@utilities/react/get-device/get-device";
import { MobileLevelSection } from "@components/C01_SiteHeader/mobileMenu/MobileLevelSection";
import { handleItemClick } from "@components/C01_SiteHeader/mobileMenu/utils";
import NotificationBannerMobile from "@components/NotificationBannerMobile/NotificationBannerMobile";

export interface SiteHeaderProps {
  logo: NextImageProps;
  loginText: string;
  logoutText: string;
  loginUrl: string;
  logoutUrl: string;
  megaNav: NavLinkList[];
  secondaryLinks: GeneralLinkNornalized[];
  skipToContentText: string;
  areaOfInterestLinks: GeneralLinkNornalized[];
  areaOfInterestText: string;
  searchHelpText: string;
  searchPlaceholderText: string;
  searchResultsUrl: string;
}

export const SiteHeader = ({
  content,
  theme,
}: {
  content: SiteHeaderProps;
  theme?: any;
}) => {
  const {
    logo,
    loginText,
    logoutText,
    loginUrl,
    logoutUrl,
    megaNav,
    secondaryLinks,
    skipToContentText,
    areaOfInterestLinks,
    areaOfInterestText,
    searchHelpText,
    searchPlaceholderText,
    searchResultsUrl,
  } = content;

  const { user } = useContext(UserContext);

  const { showSearch, setShowSearch, mobileMenuHistory, setMobileMenuHistory } =
    useContext(NavigationStateContext);
  const device = useDevice();
  const isMobile = device === "mobile";

  return (
    <>
      {!!mobileMenuHistory?.length && isMobile && (
        <div
          onClick={(e) => {
            setMobileMenuHistory([]);
            navigationGAClick(e);
          }}
          className={clsx(theme["bg-mobile-menu"])}
        />
      )}

      <NotificationBannerMobile />

      <a href="#skipnav" className={theme.skip}>
        {skipToContentText}
      </a>
      <header className={theme["site-header"]}>
        <div className={clsx("wrapper", theme.wrapper)}>
          <nav className={theme.top}>
            <ul>
              {secondaryLinks?.map((item, i) => {
                if (user && item?.url === "/create-a-profile") return;

                if (user && item?.url === "/portal") {
                  return (
                    <li key={`${item}${i}`}>
                      <a
                        href="https://portal.london.edu/?forwardAzureAD=true"
                        onClick={(e) => {
                          navigationGAClick(e);
                        }}
                      >
                        {item?.linkText}
                      </a>
                    </li>
                  );
                }

                return (
                  <li key={`${item?.linkText}${i}`}>
                    <Link href={item?.url || "#"}>
                      <a onClick={navigationGAClick}>{item?.linkText}</a>
                    </Link>
                  </li>
                );
              })}
              <li>
                <a href={user ? logoutUrl : loginUrl}>
                  {user ? logoutText : loginText}
                </a>
              </li>
              {user && (
                <li className={theme.icon}>
                  <Link href="/my-profile">
                    <a aria-label="My Account">
                      <ProfileSvg />
                    </a>
                  </Link>
                </li>
              )}
              <li className={theme.icon}>
                <a
                  href=""
                  aria-label="Search"
                  aria-haspopup="true"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSearch(!showSearch);
                  }}
                >
                  <SearchSvg />
                </a>
              </li>

              {/* NEW mobile nav */}
              <li className={`${theme.icon} ${theme["mobile-btn"]}`}>
                <a
                  href="#"
                  aria-label="Menu"
                  onClick={(e) => {
                    e.preventDefault();
                    handleItemClick(
                      mobileMenuHistory,
                      setMobileMenuHistory,
                      megaNav,
                      undefined,
                      undefined,
                      undefined
                    );
                  }}
                >
                  <span className="icon-hamburger">
                    <HamburgerSvg />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <div className={theme.main}>
            <div className={theme.logo}>
              <Link href="/">
                <a onClick={navigationGAClick}>
                  <div className={clsx("image-hover", theme.image)}>
                    <Image
                      src={logo?.src}
                      alt="London Business School logo"
                      layout="fill"
                    />
                  </div>
                </a>
              </Link>
            </div>
            <MainNav megaNav={megaNav} secondaryLinks={secondaryLinks} />
          </div>
          {isMobile ? (
            mobileMenuHistory?.map((level, index) => {
              return (
                <MobileLevelSection
                  key={index}
                  levelData={level}
                  isFirstLevel={index === 0}
                  secondaryLinks={index === 0 ? secondaryLinks : undefined}
                  user={user}
                  theme={theme}
                />
              );
            })
          ) : (
            <MegaNav megaNav={megaNav} />
          )}
        </div>
        <HeaderSearch
          content={{
            areaOfInterestLinks,
            areaOfInterestText,
            searchHelpText,
            searchPlaceholderText,
            searchResultsUrl,
          }}
          show={showSearch}
          close={() => {
            setShowSearch(false);
          }}
        />
      </header>
      <div id="skipnav" />
    </>
  );
};

export default themr("SiteHeader", styles)(SiteHeader);
