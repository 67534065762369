import { themr } from "@friendsofreactjs/react-css-themr";
import { useInView } from "react-hook-inview";
import AnimatedNumber, { AnimatedNumbersData } from "./C245_AnimatedNumber";
import styles from "./C245_AnimatedNumbers.module.scss";

interface AnimatedNumbersProps extends AnimatedNumbersData {}

export const AnimatedNumbers = (props: {
  content: AnimatedNumbersProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { animatedNumbers } = content;
  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  /* QA test condition - Once passed QA this consition can be removed */
  if (process.env.NEXT_PUBLIC_URL !== "https://www.london.edu") {
    console.log("C245_AnimatedNumbers component: ", content);
  }
  /* end QA test condition */

  return (
    <div
      ref={ref}
      className={`component ${theme["animated-numbers"]} ${
        animatedNumbers.length === 1 && theme.solo
      }`}
    >
      <div className="wrapper">
        {animatedNumbers.map((animatedNumber, index) => {
          return (
            <AnimatedNumber
              key={`${animatedNumber.heading}${index}`}
              isVisible={isVisible}
              {...animatedNumber}
            />
          );
        })}
      </div>
    </div>
  );
};

export default themr("AnimatedNumbers", styles)(AnimatedNumbers);
